

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        // initialization of go to
        $.DVCore.components.DVGoTo.init('.js-go-to');




          }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  $(window).on('load', function () {


    var rellax = new Rellax('.rellax');


  });
  $(document).on('ready', function () {
    (function($) {

  $.fn.randomize = function(childElem) {
    return this.each(function() {
        var $this = $(this);
        var elems = $this.children(childElem);

        elems.sort(function() { return (Math.round(Math.random())-0.5); });

        $this.detach(childElem);

        for(var i=0; i < elems.length; i++)
          $this.append(elems[i]);

    });
  }
  })(jQuery);

    //$('#support-block-sidebar').randomize( 'div.employee-switch-div','div.employee-img');



    //$('div.employee-switch-div > img').toggleClass( 'd-block', ( Math.random() < .5 ) );



    // initialization of sticky blocks
    // var rellax = new Rellax('.rellax');

    $.DVCore.components.DVCubeportfolio.init('.cbp');
      $.DVCore.components.DVFancyBox.init('.js-fancybox');
        // initialization of hamburgers
        $.DVCore.components.DVHeaderFullscreen.init($('#fullscreen'));
        $.DVCore.components.DVHamburgers.init('#hamburgerTrigger', {
          afterClose: function() {
            $('.collapse.show').trigger('click');
          }
        });

        // Navbar collapse closing on 'ESC' keyboard and 'close' hamburger button
        $('#headerToggler').on('click', function(e) {
          $('#fullscreenNav .collapse').collapse('hide');
        });

        $(document).on('keydown', function (e) {
          if (e.keyCode && e.keyCode === 27) {
            $('#fullscreenNav .collapse').collapse('hide');
          }
        });

        $(".dv-fullscreen__nav-link").click(function () {
               $("#fullscreen").removeClass("dv-fullscreen--showed");
           });

    $.DVCore.components.DVHamburgers.init('#hamburgerTrigger');
     // initialization of popups
     $.DVCore.components.DVUnfold.init($('[data-unfold-target]'));

     $.DVCore.components.DVSlickCarousel.init('.js-slick-carousel');


   });
  // JavaScript to be fired on all pages, after page specific JS is fired


})(jQuery); // Fully reference jQuery after this point.
